import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import GenericForm from '~/components/GenericForm/GenericForm';
import getSchema from '~/library/schemas/task';

//elements
import LoadingSpinner from '~/elements/LoadingSpinner';

//utils
import customerFeature from '~/utils/customerFeature';

const defaultUiSchema = {
    'ui:field': 'layout',
    'ui:layout:hideframe': true,
    'ui:layout': [
        {
            'ui:subtitle': 'Task basic',
            'ui:className': 'profile-edit-form__group',
            project_id: { xs: 6, sm: 4, md: 4 },
            name: { xs: 6, sm: 4, md: 4 },
            task_number: { xs: 4, sm: 4, md: 4, },
            status: { xs: 8, sm: 4, md: 4, },
            task_type: { sm: 4, md: 4, },
            assignee_id: { md: 12 },
        },
        {
            'ui:subtitle': 'Customer info',
            'ui:className': 'profile-edit-form__group',
            contact_person: { xs: 6, md: 4, },
            email: { xs: 6, md: 4, },
            phone: { xs: 6, md: 4, },
        },
        {
            'ui:subtitle': 'Location',
            'ui:className': 'profile-edit-form__group',
            address: { xs: 6, md: 4, },
            post_place: { xs: 6, md: 4, },
            post_number: { xs: 6, md: 4, },
            g_nr: { xs: 6, md: 4, },
            b_nr: { xs: 4, md: 4, }
        },
        {
            'ui:subtitle': 'HMS Requirement',
            'ui:className': 'profile-edit-form__group',
            data: { md: 12 },
        },
        {
            'ui:subtitle': 'Costs and expenses',
            'ui:className': 'profile-edit-form__group',
            est_hours: { md: 6 },
            est_work: { md: 6 },
        },
        {
            'ui:subtitle': 'Task documents',
            'ui:className': 'profile-edit-form__group',
            description: { md: 12 },
            attachments: { md: 12 },
        },
    ],
    data: {
        'ui:field': 'layout',
        'ui:layout:hideframe': true,
        'ui:flex': true,
        'ui:layout': [
            {
                require_hms: { className: 'col-md-3 mt-1' },
                security_analyze: { className: 'col-md-3 mt-1' },
                start_date: { className: 'col-md-3' },
                finish_date: { className: 'col-md-3' },
            },
        ],
        require_hms: {
            'ui:widget': 'CheckBoxWidget',
        },
        security_analyze: {
            'ui:widget': 'CheckBoxWidget',
        },
    },
    est_work: {
        'ui:widget': 'Radio',
    },
    start_time: {
        'ui:widget': 'TimeSelect',
    },
    end_time: {
        'ui:widget': 'TimeSelect',
    },
    gps_data: {
        'ui:widget': 'GpsCoordinatesWidget',
    },
    distance_from_company_location: {
        'ui:widget': 'GpsCoordinatesWithRoutesWidget',
    },
    attachments: {
        'ui:widget': 'ImageUpload',
        'ui:imageContext': {
            model: 'Task',
            fileType: 'docs',
            id: 0,
        },
    },
    status: {
        'ui:widget': 'TaskStatuses',
    },
    assignee_id: {
        'ui:widget': 'MembersWidgetMultipleSelect',
    },
    project_id: {
        'ui:widget': 'ProjectsWidget',
        'ui:FormMode': {
            widgetMode: 'manager'
        },
    },
    auto_log_on_days: {
        'ui:widget': 'DaysOfWeek',
    },
    scheduled_days: {
        'ui:widget': 'DaysOfWeek',
    },
    Shifts: {
        classNames: 'no-fieldset-title',
        'ui:widget': 'ShiftsWidget',
    },
    description: {
        'ui:widget': 'textarea',
        'classNames': 'textarea-100'
    }
};


class TasksForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: getSchema({ config: props.commonStore.config, taskStore: props.taskStore }),
            uiSchema: defaultUiSchema
        };
    }

    getListUrl() {
        return '/admin/tasks';
    }

    async loadData() {
        const { taskStore, userStore, commonStore } = this.props;
        const { currentUser } = userStore;
        this.setState({
            schema: getSchema({
                config: commonStore.config,
                taskStore
            }),
        });
        let getId = (this.props.router.location.state && this.props.router.location.state.tasks) || null;
        if (!getId) {
            this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, null) });
            return taskStore.returnDefaultNew(currentUser);
        }
        const task = await taskStore.load(getId);
        this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, task.task) });
    }

    prepareSchema(_schema, currentEntity) {
        const schema = cloneDeep(_schema);
        const { userStore, commonStore } = this.props;
        const { currentUser } = userStore;
        schema.name = { 'ui:placeholder': this.props.t('Task Name') };
        schema.task_number = { 'ui:placeholder': this.props.t('Leave blank to auto-populate') };
        schema.attachments = {
            'ui:widget': 'AttachmentsWidget',
            'ui:imageContext': {
                model: 'Task',
                fileType: 'docs',
                id: currentEntity && currentEntity.id !== 'add' ? currentEntity.id : 0,
                existingAttachment: currentEntity && currentEntity.Attachments ? currentEntity.Attachments : [],
            },
        };
        if (currentUser.user_type === 'member') {
            schema.assignee_id.classNames = 'd-none';
            if (customerFeature('union_group')) {
                schema['ui:layout'].push({
                    'ui:subtitle': 'Shifts',
                    Shifts: { md: 12 },
                });
            }
        }

        if (commonStore?.config?.client?.calculate_task_distance_with_company) {
            schema['ui:layout'][2].distance_from_company_location = { xs: 8, md: 4, }
            schema['ui:layout'][2].distance_type = { md: 4 }
        }
        else
            schema['ui:layout'][2].gps_data = { xs: 8, md: 4, }
        return schema;
    }

    componentWillMount() {
        this.props.taskStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    async recalcGps(e) {
        return this.props.taskStore.recalcGpsForTask(e, false, this.props.commonStore, this.props.t);
    }

    onSave(values) {
        const { taskStore, t, commonStore } = this.props;
        const clientData = commonStore.config && commonStore.config.client && commonStore.config.client.data;
        let getId = (this.props.router.location.state && this.props.router.location.state.tasks) || null;
        if (values?.assignee_id === 'NaN' || values?.assignee_id === '') {
            commonStore.addNotification(t('Please Assign Members'), null, 'error');
            return false;
        }
        values.members = values.assignee_id.split(',').map(id => {
            return { user_id: parseInt(id) };
        });
        values.assignee_id = `${values.members[0].user_id}`;
        const isGPSObligatory = clientData.basicRules.workinghourGPSObligatory;
        if (isGPSObligatory) {
            if (values.gps_data == null || values.gps_data === undefined || values.gps_data === '') {
                commonStore.addNotification(t('Please add address.'), null, 'error');
                return false;
            }
        }
        if (values.distance_from_company_location)
            values.data.distance_from_company_location = values.distance_from_company_location;

        return taskStore.save(values, !getId).then(result => {
            if (!result.task || !result.task.id) {
                // some error
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return false;
            }
            commonStore.addNotification(t('Saved'), null, 'success');

            if (!getId && commonStore.config?.integration_details?.product === 'tripletex') {
                const str = t(`Do You want to Sync Task with Tripletex`);
                confirmAlert({
                    title: this.props.t('Confirm to Sync'),
                    message: str,
                    buttons: [
                        {
                            label: this.props.t('Yes'),
                            onClick: () => {
                                return taskStore.saveIntoIntegration(result.task).then((res2) => {
                                    taskStore.resetLastListLoadTime();
                                    commonStore.addNotification(t(res2.message), null, 'success');
                                    return res2;
                                });
                            },
                        },
                        {
                            label: this.props.t('No'),
                            onClick: () => { },
                        },
                    ],
                })
            }
            this.getBack()
            return true;
        });

    }

    getBack = () => {
        const { page } = this.props.router.location.state || {};
        this.props.router.navigate('/admin/tasks', { state: { page: page, isEdit: true } });
    }

    render() {
        const { taskStore, add } = this.props;
        const { loading, currentEntity } = taskStore;
        const { schema, uiSchema } = this.state;

        if (loading) {
            return <LoadingSpinner />;
        }
        return (
            <div className="primary-page">
                <GenericForm
                    entity={currentEntity.task}
                    uiSchema={uiSchema}
                    recomputeFields={['gps_data', 'data.addressCache', 'distance_from_company_location', 'distance_type']}
                    schema={schema}
                    onChange={async data => this.recalcGps(data)}
                    translationScope="forms.task"
                    onSave={values => this.onSave(values)}
                    listUrl={this.getListUrl()}
                    isAdding={add}
                    goBack={() => this.getBack()}
                />
            </div>
        );
    }
}

export default inject('taskStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(TasksForm))));
