import {
    ClockSVG, ClockCheckSVG, ClockExclamationSVG, CalendarClockSVG,
    ClockAtLocationSVG, MultipleShiftsSGV, CircleExclamationSGV
} from '../../../utils/constants';
const VacationAbsenceTemplateResolver = ({ event, t, currentView }) => {
    const titleText = `${t(event.source === 'vacation' ? "Vacation" : "Absence")} ${event.employementGrade && event.employementGrade !== 100 ? ` (${event.employementGrade}%)` : ""}`;

    return (
        <div key={event.id} className="schedule_non_shift_event_template absence_vacation">
            <span>{titleText}</span>
            {!!currentView && <span>{event.resourceName ? event.resourceName : "No Name"}</span>}
        </div>
    );
}

const AvailabilityTemplateResolver = ({ event, t, currentView }) => {
    const titleText = t(event.availability_type === 'available' ? "Available" : "Not Available");
    let clockText = event.from == '12:00 am' && event.to == '12:00 am' ? null : event.from + ' -' + event.to;
    return (
        <div key={event.id}
            className={`schedule_non_shift_event_template 
                ${event.availability_type.replace(' ', '_')} 
                ${clockText && !!currentView ? 'extra_1em' : ''}`}
        >
            <span>{titleText}</span>
            {!!currentView && <span>{event.resourceName ? event.resourceName : "No Name"}</span>}
            {clockText && <span>{clockText}</span>}
        </div>
    );
}


const getStatusSGV = (event) => {
    if (!!event.timelogCount) {
        if (event.timelogStatus === 'draft')
            return ClockAtLocationSVG({ width: 12, strokeColor: "#2550AC" });
        else if (event.hasLogFromDiffLocation)
            return CircleExclamationSGV({ width: 14, fillColor: "#FF4747" });
        else
            return ClockCheckSVG({ width: 12, strokeColor: "#038102" });
    } else {
        if (!!event.resourceId)
            return ClockSVG({ width: 12, strokeColor: "#877e7e" });
        else
            return ClockExclamationSVG({ width: 12, strokeColor: "#FF4747" });
    }
}

const ShiftTemplateResolver = ({ event, t, currentView, schedulerData, mustBeHeight, mustAddCssClass, agendaMaxEventWidth }) => {
    const shiftTime = schedulerData.behaviors.getEventTextFunc(schedulerData, event);
    const taskName = schedulerData.behaviors.getEventTaskName(schedulerData, event);
    const hasBonus = event.addBonus;
    const multiplePendingShifts = event.shiftCount && event.shiftCount > 1 ? true : false;
    let divStyle = { height: mustBeHeight };
    if (agendaMaxEventWidth) divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
    const shiftDetails = event.workLogStatus !== 'none' ? String(t(event.workLogStatus)).toUpperCase() : taskName;
    return (
        <div key={event.id}
            className={`schedule_shift_event_template 
                ${event.isEditable ? '' : 'read_only'}
                ${event.resourceId === 0 ? 'pending' : event.workLogStatus.replace(" ", "_").toLowerCase()}`}
            style={divStyle}
        >
            {!!currentView &&
                <span className='shift_details shift_resource_name' title={event.resourceName}>
                    {event.resourceName}
                </span>
            }
            <span className={`shift_details ${hasBonus ? 'has_bonus' : ''}`} title={shiftDetails}>
                {hasBonus && <i className="fa fa-star" style={{ color: 'goldenrod' }} title={t('Bonus')} />}
                {multiplePendingShifts && MultipleShiftsSGV({ width: 12 })}
                {multiplePendingShifts ? `${event.shiftCount} ${shiftDetails}` : shiftDetails}
            </span>
            <span className='shift_time'>
                {CalendarClockSVG({ width: 13, strokeColor: "#877e7e" })}
                <span title={shiftTime} className='ms-1'>{shiftTime}</span>
            </span>
            <span className='shift_time'>
                {getStatusSGV(event)}
                <span title={event.timelogStatus} className='ms-1'
                    style={{ color: !!event.timelogCount && !event.hasLogFromDiffLocation ? '#2550AC' : '#FF4747' }}>
                    {t(event.timelogTitle)}
                </span>
            </span>
        </div>
    )

}

function resolver({ params }) {
    const { schedulerData, event, mustAddCssClass, mustBeHeight, agendaMaxEventWidth, t } = params;
    const currentView = schedulerData.currentView;
    if (event.source === 'vacation' || event.source === 'absence') {
        return <VacationAbsenceTemplateResolver event={event} t={t} currentView={currentView} />
    }
    else if (event.availability_type) {
        return <AvailabilityTemplateResolver event={event} t={t} currentView={currentView} />
    }
    else {
        return <ShiftTemplateResolver
            event={event} t={t}
            currentView={currentView}
            schedulerData={schedulerData}
            agendaMaxEventWidth={agendaMaxEventWidth}
            mustAddCssClass={mustAddCssClass}
            mustBeHeight={mustBeHeight}
        />
    }
}

export default resolver;