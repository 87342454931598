import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { find, filter } from 'lodash';

import Select from '../../../elements/Select';
import withLocalization from '../../../hoc/withLocalization';

const ChecklistWidgetMobx = inject('checklistStore')(withLocalization(observer(class ChecklistWidgetMobx extends Component {
    notLoadedYet = true;

    constructor(props) {
        super(props);
        this.state = {
            inputValue: ''
        };
    }

    componentDidMount(){
        this.props.checklistStore.loadLookupCheckWidget(this.props.mode, '',true).then(list => {
            const { value } = this.props;
            const {
               defaultIfOne, defaultByGeneric
            } = this.props;
            const options = this.prepareOptions(list);                
            if (!value && defaultIfOne && options.length === 1) {
                this.props.onChange(options[0].value, options[0]);
            }
            if (defaultByGeneric && this.props.filterByProject && !value) {
                const o = find(options, o => (o.is_default_for_project = true));
                if (o) this.props.onChange(o.value, o);
            }
        });
    }

    handleInputChange = newValue => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    prepareOptions = array => {
        const { checklistStore } = this.props;
        let all = checklistStore.isAllChecklistShow;
        const { filterByUser, filterByProject } = this.props; 
        let _array = (filterByProject && !all) ? filter(array, a => a.project_id == parseInt(filterByProject)) : array;
        return _array
            ? _array.map(c => {
                return {
                    value: `${c.id}`,
                    label: `${c.name}`
                };
            })
            : [];
    };

    render() {
        const { loadingLookup, currentChecklistLookup } = this.props.checklistStore;
        const {
            value,
            onChange,
            mode,
            defaultIfOne,
            filterByUser,
            t,
            disabled,
            emptyOption,
            emptyOptionLabel,
            label = '',
        } = this.props;
        const options = this.prepareOptions(currentChecklistLookup);
        const currentSelect = find(options, o => {
            return String(o.value) === String(value);
        });        

        const inputView = !!label;
        return (
            <div className={`managers-widget ${inputView ? 'input-block' : ''}`}>
                {inputView && <span className="input-block__label">{label}</span>}
                <Select
                    options={options}
                    emptyOption={emptyOption}
                    inputView={inputView}
                    emptyOptionLabel={emptyOptionLabel}
                    value={currentSelect}
                    isDisabled={disabled}
                    onChange={id => onChange(id.value, id)}
                    style={{ width: '100%' }}
                />
            </div>
        );
    }
})));

export const ChecklistWidget = observer(class ChecklistWidget extends Component {
    render() {
        return <ChecklistWidgetMobx {...this.props} mode="managers" />;
    }
});
